<template>
	<form
		class="main-wrapper mt-4"
		@submit.prevent="handleSubmit"
	>
		<CRow>
			<CCol md="12">
				<h4 class="mb-4">
					Rule condition
				</h4>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="9">
				<CInput
					v-model.trim="$v.name.$model"
					:is-valid="!$v.name.$error && null"
					:invalid-feedback="$t('global.error.required')"
					label="Rule name"
					type="text"
					data-test-id="input-name"
				/>
			</CCol>
			<CCol md="3">
				<CInput
					v-model="score"
					:is-valid="!$v.score.$error && null"
					:description="$v.score.$error ? null : 'Can be + / -'"
					invalid-feedback="Please input score number (+ / - and 0-9) and maximum is 99,999,999"
					label="Score"
					type="text"
					placeholder=" +100"
					data-test-id="input-score"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12" class="mb-5">
				<label class="label label-rule d-block">
					Set rule by
				</label>
				<BaseButtonGroup
					v-model="type"
					:list="SEARCH_WEIGHT_TYPE_OPTIONS"
					@input="handleSelectedType"
				/>
			</CCol>
		</CRow>
		<CRow class="mb-5">
			<CCol md="12">
				<component
					:is="itemListByType"
					:list="items"
					:condition="condition"
					:price="price"
					:is-valid="!$v.items.$error && !$v.price.$error "
					:invalid-feedback="$t('global.error.required')"
					@onChange="handleValueChange"
				/>
				<hr class="mt-5">
			</CCol>
		</CRow>

		<BaseActionPanelStickyFooter
			:disabled-confirm="create.isCreating"
			content-class="main-wrapper"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'SearchWeightList'})"
		/>
	</form>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, helpers, requiredIf } from 'vuelidate/lib/validators';
import SearchWeightBrandForm from '@/components/SearchWeightBrandForm.vue';
import SearchWeightProductForm from '@/components/SearchWeightProductForm.vue';
import SearchWeightCategoryForm from '@/components/SearchWeightCategoryForm.vue';
import SearchWeightPriceForm from '@/components/SearchWeightPriceForm.vue';
import SearchWeightPromotionForm from '@/components/SearchWeightPromotionForm.vue';
import { SEARCH_WEIGHT_TYPES, SEARCH_WEIGHT_TYPE_OPTIONS } from '../enums/searchWeights';
import { searchWeightScorePattern } from '../regex';
import { priceToAPI } from '../assets/js/helpers';

export default {
	name: 'SearchWeightCreate',
	validations() {
		return {
			name: {
				required,
			},
			score: {
				required,
				score: helpers.regex('score', searchWeightScorePattern),
			},
			type: {
				required,
			},
			items: {
				required: requiredIf(() => {
					return ![SEARCH_WEIGHT_TYPES.price].includes(this.type);
				}),
			},
			price: {
				required: requiredIf(() => {
					return SEARCH_WEIGHT_TYPES.price === this.type;
				}),
			},
		};
	},
	mixins: [validationMixin],
	data() {
		return {
			name: null,
			score: null,
			type: SEARCH_WEIGHT_TYPES.product, // Default type is sku (product)
			items: [],
			condition: null,
			price: null,
			SEARCH_WEIGHT_TYPES,
			SEARCH_WEIGHT_TYPE_OPTIONS,
		};
	},
	computed: {
		...mapState('searchWeights', {
			create: 'create',
		}),

		itemListByType() {
			switch (this.type) {
				case SEARCH_WEIGHT_TYPES.brand: return SearchWeightBrandForm;
				case SEARCH_WEIGHT_TYPES.category: return SearchWeightCategoryForm;
				case SEARCH_WEIGHT_TYPES.product: return SearchWeightProductForm;
				case SEARCH_WEIGHT_TYPES.price: return SearchWeightPriceForm;
				case SEARCH_WEIGHT_TYPES.promotion: return SearchWeightPromotionForm;
				default: return null;
			}
		},
	},
	methods: {
		...mapActions({
			createSearchWeight: 'searchWeights/createSearchWeight',
		}),

		handleSelectedType(type) {
			this.type = type;
			// reset every time that type is changed
			this.items = [];
			this.price = null;
			this.condition = null;
		},

		handleValueChange(data) {
			if (this.type === SEARCH_WEIGHT_TYPES.price) {
				this.price = data.price;
				this.condition = data.condition;
				return;
			}

			this.items = data;
		},

		async handleSubmit() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				let params = {
					name: this.name,
					type: this.type,
					score: Number(this.score),
				};

				switch (this.type) {
					case SEARCH_WEIGHT_TYPES.product: {
						params = {
							...params,
							skus: this.items,
						};
						break;
					}
					case SEARCH_WEIGHT_TYPES.price: {
						params = {
							...params,
							condition: this.condition,
							price: priceToAPI(Number(this.price)),
						};
						break;
					}
					case SEARCH_WEIGHT_TYPES.promotion: {
						params = {
							...params,
							condition_param: [
								{
									type: SEARCH_WEIGHT_TYPES.promotion,
									values: this.items.map((item) => item.id),
								},
							],
						};
						break;
					}
					default:
						params = {
							...params,
							item_ids: this.items.map((item) => item.id),
						};
						break;
				}

				await this.createSearchWeight(params);

				this.$router.push({ name: 'SearchWeightList' });
			}
		},
	},
};
</script>
